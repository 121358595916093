@import url(https://fonts.googleapis.com/css?family=Acme|Indie+Flower&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body, input, select {
  font-family: 'Acme', 'sans-serif', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

input[type="button"], #view_hide_meals, .menu_categories span, #menu_meal_selection p {
  cursor: pointer;
}

input[type="button"] {
  border: none;
  background: none;
}

.wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

header {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

header h2 {
  color: yellow;
}

#site_title {
  color: black;
  position: relative; 
  left: -81px;
}

#meals_lists {
  height: 60px;
  display: flex;
  justify-content: space-around;
}

#meals_lists h4 {
  text-align: center;
  margin-bottom: 5px;
}

#meals_lists ul {
  padding: 0;
  margin-bottom: 5px;
}

#meals_lists li {
  list-style: none;
  text-align: center;
}

#meals_lists input {
  color: red;
}

.meals_lists {
  width: 150px;
}

#everything_list {
  color: rgb(73, 121, 255);
}

#meals_lists li, main p, input.add_item, .add_to_menu_form span {
  font-family: 'Indie Flower', cursive, 'Courier New', Courier, monospace;
}

main p:hover, .meals_delete_btns:hover  {
  font-weight: bold;
}

.meals_delete_btns {
  width: 100%;
  text-align: center;
}

#view_hide_meals {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

main {
  width: 100%;
}

#menu_meal_selection {
  display: flex;
}

#menu_meal_selection h4 {
  margin: 5px 0;
}

#menu_meal_selection p {
  margin: 5px 0;
}

#menu, #meal {
  width: 50%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#menu {
  background: #fff;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
}

.menu_categories, #menu p {
  display: flex;
  justify-content: space-between;
}

.menu_categories span, #menu p span { 
  margin: 0 10px;
}

#menu p span {
  color: red;
}

.add_to_menu_form {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.add_item {
  width: 100%;
  font-size: 15px;
  color: blue;
  border: 1px dotted blue;
  text-transform: lowercase;
}

.hide_element {
  display: none;
}

#cancel_add_item {
  width: 8%;
  font-size: 16px;
  color: red;
  display: flex; 
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 2px;
  cursor: pointer;
}

#cancel_add_item:hover {
  font-weight: bold;
}

#meal {
  padding: 10px;
  background: #fff;
  box-sizing: border-box;
}

#meal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#meal_header form {
  border: 1px solid blue;
}

#meal_header input {
  font-size: 16px;
  font-weight: bold;
}

#meal p {
  position: relative;
}

#meal p span {
  position: absolute;
  right: 0px;
}

select {
  width: 100%;
  border: none;
}

#footer {
  margin: 10px 0 15px;
  color: rgb(167, 167, 167);
}

@media only screen and (min-width: 600px) {
  .wrapper {
    width: 600px;
  }
}
#referrer {
    width: 100%;
    margin: 0;
    font-size: 16px;
    line-height: 25px;
    background: linear-gradient(90deg, rgba(255,255,200,1) 50%, rgba(255,255,255,1) 75%);
}

#referrer p, #referrer a, #referrer a:visited {
    margin: 0;
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}

#referrer a {
    text-decoration: none;
}

@media only screen and (min-width: 750px) {
    #referrer {
        background: linear-gradient(90deg, rgba(255,255,200,1) 25%, rgba(255,255,255,1) 50%);
    }
}
